import $axios from './index'

//直播是否购买
export function live_isBuy(data){
	const url = '/api/pc/v1/live/isBuy';
	return $axios.post(url,data);
}

//套餐是否购买
export function package_isBuy(data){
	const url = '/api/pc/v1/course/package/isBuy';
	return $axios.post(url,data);
}

//直播讲义下载
export function coursewareList(data){
	const url = '/api/app/v1/live/coursewareList';
	return $axios.post(url,data);
}

//首页banner
export function banner_list(data){
	const url = '/api/app/v1/live/coursewareList';
	return $axios.post(url,data);
}